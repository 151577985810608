import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import NextLink from 'next/link';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import SidenavCollapse from 'examples/Sidenav/SidenavCollapse';
import SidenavRoot from 'examples/Sidenav/SidenavRoot';
import sidenavLogoLabel from 'examples/Sidenav/styles/sidenav';
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav
} from 'context';
// import BOLogo from 'assets/svg/BOLogo';
import PBBLogo from 'assets/svg/PBBLogo';
import routes from 'routes';
import { DrawerProps } from '@mui/material/Drawer';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';

function Sidenav({ brand, brandName, ...rest }: ISidenav & DrawerProps) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const { pathname } = useRouter();
  const collapseName = pathname.replace('/', '');
  const {
    AuthStore: { Permissions }
  } = useStore();

  let textColor = 'white';

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = 'dark';
  } else if (whiteSidenav && darkMode) {
    textColor = 'inherit';
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener('resize', handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleMiniSidenav);
  }, [dispatch, pathname]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = useMemo(
    () =>
      routes(Permissions)
        .filter((r) => r.can)
        .map(({ type, name, icon, key, route }) => {
          let returnValue;

          if (type === 'collapse') {
            returnValue = (
              <NextLink key={key} href={route} passHref>
                <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
              </NextLink>
            );
          } else if (type === 'divider') {
            returnValue = (
              <Divider
                key={key}
                light={
                  (!darkMode && !whiteSidenav && !transparentSidenav) ||
                  (darkMode && !transparentSidenav && whiteSidenav)
                }
              />
            );
          }

          return returnValue;
        }),
    [Permissions, collapseName, darkMode, transparentSidenav, whiteSidenav]
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} textAlign="center" display="flex" justifyContent="center">
        <MDBox
          display={{ xs: 'block', xl: 'none' }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: 'pointer' }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox display="flex" alignItems="center">
          <NextLink href="/" passHref>
            <MDBox sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}>
              <PBBLogo />
            </MDBox>
          </NextLink>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>

      <MDTypography
        mb="10px"
        component="h6"
        bottom={0}
        position="absolute"
        left="50%"
        sx={{
          transform: 'translateX(-50%)',
          fontSize: '0.8rem',
          opacity: 0.7
        }}
        fontWeight="medium"
        color={textColor as any}
      >
        Bamboo&#169; {new Date().getFullYear()}
      </MDTypography>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  brand: ''
};

// Typechecking props for the Sidenav
interface ISidenav {
  brand?: string;
  brandName: string;
  // routes: Record<string, any>[];
}

export default observer(Sidenav);
